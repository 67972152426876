export function getFullUrl(customSlug= null) {
  if ('undefined' === typeof customSlug || null === customSlug) {
    const router = useRouter()
    const { currentRoute } = router

    return `${getBaseUrl()}${currentRoute.value.fullPath}`
  }

  return `${getBaseUrl()}${customSlug}`
}

export function getBaseUrl() {
  const config = useRuntimeConfig()
  return config.public.publicBaseUrl || 'https://localhost:3000'
}
