export default function useIsDesktop() {
  const isDesktop = ref(false)

  const updateIsDesktop = () => {
    isDesktop.value = window.innerWidth >= 992
  }

  onMounted(() => {
    updateIsDesktop()
    window.addEventListener('resize', updateIsDesktop)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateIsDesktop)
  })

  return { isDesktop }
}
