<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M249.752 36.4183C258.083 28.0871 258.083 14.5796 249.752 6.24839C241.421 -2.0828 227.913 -2.0828 219.582 6.24839L128 97.8302L36.4183 6.24839C28.0871 -2.0828 14.5796 -2.0828 6.24839 6.24839C-2.0828 14.5796 -2.0828 28.0871 6.24839 36.4183L97.8302 128L6.24839 219.582C-2.0828 227.913 -2.0828 241.421 6.24839 249.752C14.5796 258.083 28.0871 258.083 36.4183 249.752L128 158.17L219.582 249.752C227.913 258.083 241.421 258.083 249.752 249.752C258.083 241.421 258.083 227.913 249.752 219.582L158.17 128L249.752 36.4183Z"
      fill="currentColor"
    />
  </svg>
</template>
